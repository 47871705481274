import { Box } from "theme-ui";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession, signOut } from "next-auth/react";

// components
import Sidebar from "./Sidebar";
import TopBarLegacy from "./TopBarLegacy";

// hooks
import useSessionStorage from "../../hooks/useSessionStorage";
import ChangePasswordPage from "@/components/Account/ChangePassword";

export interface Props {
  children: React.ReactNode;
}

export function DefaultLayout({ children }: Props) {
  const { data } = useSession();
  const { query, pathname } = useRouter();

  const [isSidebarExpanded, setIsSidebarExpanded] = useSessionStorage(
    "sidebarOpen",
    true,
  );

  const isClientRoute = Boolean(query.client_id);

  const isLogingPage =
    pathname.startsWith("/auth/login") ||
    pathname.startsWith("/api/auth/signin");

  if (isLogingPage) {
    return <>{children}</>;
  }

  if (data?.error === "PasswordChangeRequired") {
    return <ChangePasswordPage />;
  }

  const is404 = pathname === "/404";
  const is500 = pathname === "/500";

  const showLegacyLayout =
    pathname === "/" &&
    data?.groups?.includes("SuperAdmins") &&
    isClientRoute === false;

  if (is404 || is500) {
    return (
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    );
  }
  if (showLegacyLayout) {
    return (
      <>
        <TopBarLegacy />
        {children}
      </>
    );
  }

  const hideSideBar = pathname.startsWith("/account/change-password");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
      }}
    >
      {hideSideBar ? null : (
        <Sidebar
          isExpanded={isSidebarExpanded}
          toggleExpanded={() => {
            const isExpandedNew = !isSidebarExpanded;
            if (typeof window !== "undefined") {
              window.sessionStorage.setItem(
                "sidebarOpen",
                isExpandedNew.toString(),
              );
            }
            setIsSidebarExpanded(isExpandedNew);
          }}
        />
      )}
      <Box
        style={{
          flex: 1,
          minHeight: "100vh",
          overflowY: "auto",
          paddingLeft: hideSideBar ? "" : isSidebarExpanded ? "208px" : "56px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
export default DefaultLayout;
