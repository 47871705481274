import {
  faChartMixed as faChartMixedSolid,
  faUserGroup as faUserGroupSolid,
  faCircleUser as faCircleUserSolid,
  faCircleCheck as faCircleCheckSolid,
  faBookBookmark as faBookBookmarkSolid,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faBars,
  faUserGroup,
  faCircleUser,
  faChartMixed,
  faCircleCheck,
  faBookBookmark,
  faArrowRightLongToLine,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { Box, Link, Image, Divider, Text } from "theme-ui";

// theming
import { theme } from "../../../themes";

// hooks
import { useClientId } from "../../../views/content-home/useClientID";

interface Props {
  isExpanded: boolean;
  toggleExpanded: () => void;
}

const Sidebar = ({ isExpanded, toggleExpanded }: Props) => {
  const { data } = useSession();
  const clientId = useClientId();
  const { query, pathname } = useRouter();

  const activeTab =
    query?.channelType === "undefined"
      ? "EMAIL"
      : (query?.channelType ?? "EMAIL");

  const isSuperAdmin = data?.groups?.includes("SuperAdmins");
  const isMyAccountPage = pathname === "/my-account";

  return (
    <Box
      className={isExpanded ? "sidebar" : "sidebar collapsed"}
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "8px",
        paddingRight: "8px",
        borderRight: `1px solid ${theme.colors.charcoal20}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: isExpanded ? "space-between" : "center",
          maxHeight: "40px",
          marginTop: "16px",
          flex: 1,
          alignItems: "center",
          paddingLeft: isExpanded ? "8px" : "0px",
          paddingRight: isExpanded ? "8px" : "0px",
        }}
      >
        <Box
          as="button"
          sx={{
            cursor: "pointer",
            width: "40px",
            height: "40px",
            padding: "8px",
            border: "none",
            color: isExpanded ? theme.colors.darkCharcoal : "#A8ABAD",
            backgroundColor: "transparent",
            borderRadius: "4px",
            ":hover": {
              textDecoration: "none",
              backgroundColor: isExpanded ? "#D4D5D6" : "#686c6f",
            },
            ":active": {
              color: "#ffffff",
              textDecoration: "none",
              backgroundColor: "#9E5ABE",
            },
          }}
          onClick={toggleExpanded}
        >
          <FontAwesomeIcon
            size="xl"
            icon={isExpanded ? faArrowRightLongToLine : faBars}
            style={{
              color: "inherit",
              cursor: "pointer",
              transform: "rotate(180deg)",
            }}
          />
        </Box>
        {isExpanded ? (
          <Link href="/" title="Retain Client Dashboard">
            <Image
              sx={{
                width: "98px",
                height: "40px",
              }}
              variant="logo"
              src="/static/retain-logo.svg"
              alt="Retain by TrueML Logo"
            />
          </Link>
        ) : null}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "16px",
          marginBottom: "16px",
          paddingLeft: isExpanded ? "8px" : "0px",
          paddingRight: isExpanded ? "8px" : "0px",
        }}
      >
        <Divider
          sx={{
            width: "100%",
            color: isExpanded ? "#D4D5D6" : "#51565A",
            height: "1px",
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "16px",
          flexDirection: "column",
        }}
      >
        {[
          {
            show: true,
            href: `/?client_id=${query.clientId}`,
            label: "Dashboard",
            pathname: ["/"],
            expandedIcon: faChartMixed,
            collapsedIcon: faChartMixed,
            activeIcon: faChartMixedSolid,
          },
          {
            show: true,
            href: `/account/${clientId}`,
            label: "Account Center",
            pathname: ["/account/[clientId]"],
            expandedIcon: faUserGroup,
            collapsedIcon: faUserGroup,
            activeIcon: faUserGroupSolid,
          },
          {
            show: true,
            href: `/content/${clientId}/home?channelType=${activeTab}`,
            label: "Content Tool",
            pathname: [
              "/content/[clientId]/home",
              "/content/[clientId]/home/templates/[templateName]",
            ],
            expandedIcon: faCircleCheck,
            collapsedIcon: faCircleCheck,
            activeIcon: faCircleCheckSolid,
          },
          {
            show: isSuperAdmin,
            href: `/content/${clientId}/portal?channelType=${activeTab}`,
            label: "Admin Tool",
            pathname: [
              "/content/[clientId]/portal",
              "/content/[clientId]/portal/templates/[templateName]",
            ],
            expandedIcon: faCircleCheck,
            collapsedIcon: faCircleCheck,
            activeIcon: faCircleCheckSolid,
          },
          {
            show: true,
            href: `https://trueaccord.com/handbook/retain`,
            label: "Handbook & FAQs",
            pathname: [],
            expandedIcon: faBookBookmark,
            collapsedIcon: faBookBookmark,
            activeIcon: faBookBookmarkSolid,
          },
        ].map(
          ({
            href,
            label,
            pathname: navlinkPathname,
            show,
            activeIcon,
            collapsedIcon,
            expandedIcon,
          }) => {
            if (show === false) return null;

            const isActive = navlinkPathname.includes(pathname);
            let backgroundColor = isActive ? "#9E5ABE" : "";
            return (
              <Box
                sx={{
                  width: "100%",
                }}
                key={label}
              >
                <Link
                  href={href}
                  sx={{
                    display: "flex",
                    justifyContent: isExpanded ? "start" : "center",
                    width: "100%",
                    alignItems: "center",
                    fontSize: "16px !important",
                    fontWeight: "400 !important",
                    color: isActive
                      ? "white !important"
                      : isExpanded
                        ? "#262C31 !important"
                        : "#A8ABAD !important",
                    ":hover": {
                      textDecoration: "none",
                      backgroundColor: isActive
                        ? backgroundColor
                        : isExpanded
                          ? "#D4D5D6"
                          : "#686c6f",
                    },
                    ":active": {
                      color: "#ffffff !important",
                      textDecoration: "none",
                      backgroundColor: "#9E5ABE",
                    },
                    borderRadius: "5px",
                    padding: "8px",
                    whiteSpace: "nowrap",
                    backgroundColor,
                  }}
                >
                  <FontAwesomeIcon
                    size="xl"
                    icon={
                      isActive
                        ? activeIcon
                        : isExpanded
                          ? expandedIcon
                          : collapsedIcon
                    }
                    style={{
                      marginRight: isExpanded ? "8px" : "0px",
                    }}
                  />
                  {isExpanded ? label : ""}
                </Link>
              </Box>
            );
          },
        )}
      </Box>

      {data && (
        <NextLink href={"/my-account"} passHref style={{ marginTop: "auto" }}>
          <Box
            sx={{
              cursor: "pointer",
              backgroundColor: isMyAccountPage ? "#9E5ABE" : "inherit",
              display: "flex",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
              marginTop: "auto",
              alignItems: "center",
              marginBottom: "16px",
              color: isMyAccountPage ? "#ffffff" : isExpanded ? "" : "#a7abad",
              justifyContent: isExpanded ? "start" : "center",
              ":hover": {
                color: isMyAccountPage
                  ? "#ffffff"
                  : isExpanded
                    ? ""
                    : "#a7abad",
                textDecoration: "none",
                backgroundColor: isMyAccountPage
                  ? "#9E5ABE"
                  : isExpanded
                    ? "#D4D5D6"
                    : "#686c6f",
              },
              ":active": {
                color: "#ffffff",
                textDecoration: "none",
                backgroundColor: "#9E5ABE",
              },
            }}
          >
            <FontAwesomeIcon
              size="xl"
              icon={isExpanded ? faCircleUserSolid : faCircleUser}
              style={{
                marginRight: isExpanded ? "10px" : "0px",
              }}
            />
            {isExpanded ? (
              <Text
                style={{
                  fontSize: "16px !important",
                  fontWeight: "400 !important",
                  color: isMyAccountPage
                    ? "#ffffff"
                    : isExpanded
                      ? "#262C31 !important"
                      : "",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inline-block",
                }}
              >
                {data?.user ? data?.user?.name || data?.user?.email : ""}
              </Text>
            ) : null}
          </Box>
        </NextLink>
      )}
    </Box>
  );
};

export default Sidebar;
