import { useIdleTimer } from "react-idle-timer";
import { signOut, useSession } from "next-auth/react";

import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { Box, Text } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  children: any;
}

const IdleWarningToast = ({ onClose }: { onClose: () => void }) => (
  <Box
    className="custom-toast"
    sx={{
      display: "flex",
      alignItems: "flex-start",
      borderRight: "2px solid #FF9F4D",
      borderTop: "2px solid #FF9F4D",
      borderBottom: "2px solid #FF9F4D",
    }}
  >
    <Box
      sx={{
        fontSize: "24px",
        minWidth: "50px",
        backgroundColor: "#FF9F4D",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        minHeight: "155px",
        alignItems: "center",
      }}
    >
      <FontAwesomeIcon size="sm" icon={faWarning} color="white" />
    </Box>
    <Box sx={{ flexGrow: 1 }}>
      <button
        style={{
          position: "absolute",
          right: 0,
          background: "none",
          border: "none",
          fontSize: "20px",
          lineHeight: "20px",
          cursor: "pointer",
          padding: "0 5px",
        }}
        onClick={onClose}
      >
        ×
      </button>
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        <Text
          sx={{
            fontWeight: 600,
            fontSize: 14,
            lineHeight: "21px",
            color: "#262C31",
          }}
        >
          Your session is expiring soon
        </Text>
        <Text
          as="p"
          sx={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "21px",
            color: "#262C31",
          }}
        >
          You will be logged out in 15 minutes. Please save your work to avoid
          losing progress.
        </Text>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            color: "#0073e6",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          <button
            onClick={onClose}
            style={{
              fontWeight: 600,
              fontSize: 14,
              color: "#003D98",
              lineHeight: "21px",
              background: "none",
              border: "none",
              cursor: "pointer",
              padding: "0 5px",
            }}
          >
            STAY LOGGED IN
          </button>
        </Box>
      </Box>
    </Box>
  </Box>
);

const IdleCheck = ({ children }: Props) => {
  const [showWarning, setShowWarning] = useState(false);

  const { data, status } = useSession();

  useEffect(() => {
    if (typeof window !== "undefined" && (window as any).Sprig && data?.user) {
      const sprig = (window as any).Sprig;
      sprig("setUserId", "portal-user-" + data.user.email);
    }
  }, [data]);

  const onIdle = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error(error);
    }
  };

  const { getRemainingTime, reset } = useIdleTimer({
    onIdle,
    timeout: 30 * 60 * 1000, // 30 minutes
    throttle: 500,
    disabled: status !== "authenticated",
  });

  const checkIdleTime = useCallback(() => {
    const remaining = Math.ceil(getRemainingTime() / 1000);
    if (remaining > 0 && remaining <= 15 * 60 && !showWarning) {
      setShowWarning(true);
    }
  }, [getRemainingTime, showWarning]);

  useEffect(() => {
    const interval = setInterval(checkIdleTime, 500);
    return () => clearInterval(interval);
  }, [checkIdleTime]);

  useEffect(() => {
    if (showWarning) {
      toast(
        ({ closeToast }) => (
          <IdleWarningToast
            onClose={() => {
              setShowWarning(false);
              reset();
              closeToast?.();
            }}
          />
        ),
        {
          className: "toast-wrapper",
          closeButton: false,
          autoClose: false,
        },
      );
    }
  }, [showWarning, reset]);

  return children;
};

export default IdleCheck;
